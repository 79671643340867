import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
 
  
    {
		label: t('ALV'),
		icon: 'Link1Icon',
		
		items: [
			{
			label: t('BSC'),
			href: 'https://ampleswap.com',
			},
			
			
			
			{
			label: t('DecimalChain'),
			href: 'https://decimal.ampleswap.com/',
			},
			
			
			
		],},
  
  
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Swap'),
        href: '/swap?outputCurrency=0x7a0b5E86d2C1e873E005780419eeE16ba741808a',
      },
      {
        label: t('Liquidity'),
        href: '/pool',

      },
    ],
  },
  
 /* {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },  */
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  }, 
  
  {
		label: t('Bridge'),
		icon: 'Link2Icon',
		href: 'https://alveybridge.com/',
  },
  
 {
		label: t('Info'),
		icon: 'InfoIcon',
		href: 'https://alveyinfo.ampleswap.com',
  },
  
  {
		label: t('V1'),
		icon: 'Link1Icon',
		href: 'https://alveyv1.ampleswap.com',
  },
  
  


		
		
		
		
		
		
		
		
		
  
  
 
        



]

export default config

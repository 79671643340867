import { ChainId, Token } from '@pancakeswap/sdk'

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
}
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x7a0b5E86d2C1e873E005780419eeE16ba741808a',
    18,
    'AUSDT',
    'Alvey USD',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
  ),
}
export const WBNB = new Token(ChainId.MAINNET, '0xCb3e9919C56efF1004E54175a01e39163a352129', 18, 'WBNB', 'Wrapped wALV')
export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token',
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token',
)
export const USDC = new Token(
  ChainId.MAINNET,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
)

const tokens = {
		alv: {
		symbol: 'ALV',
		projectLink: 'https://alvey.ampleswap.com/',
		},
		
		walv: {
		symbol: 'WALV',
		address: {
		3797: '0xCb3e9919C56efF1004E54175a01e39163a352129',
		64668: '0xCb3e9919C56efF1004E54175a01e39163a352129',
		},
		decimals: 18,
		projectLink: 'https://alveychain.com/',
		},
		
		
		
	    test: {
		symbol: 'TEST',
		address: {
		3797: '0x58F4C612D23A60b0E20C256a021C26694F0E0359',
		64668: '0x58F4C612D23A60b0E20C256a021C26694F0E0359',
		},
		decimals: 18,
		projectLink: 'https://ampleswap.com/',
		},
		
		
 // ***************************************** client token   ********************************** 		
		
		 
		
		ana: {
		symbol: 'ANA',
		address: {
		3797: '0x7a45b257fe308734b2343eb6da66b2025e0ce73e',
		64668: '',
		},
		decimals: 9,
		projectLink: 'https://angeldao.info',
		},

        	
		
		alvusdtlp: {
		symbol: 'AUSDT-WALV LP',
		address: {
		3797: '0xee8fb5bbd126bdb6ce92257e83a768d17ee90d5f',
		64668: '',
		},
		decimals: 18,
		projectLink: 'https://ampleswap.com/',
		},
		
		alvanalp: {
		symbol: 'ANA-WALV LP',
		address: {
		3797: '0x52be5e9bc12f41bb09eda3dcfb4b87b5f723a739',
		64668: '',
		},
		decimals: 18,
		projectLink: 'https://ampleswap.com/',
		},

   
 // ***************************************** default token   ********************************** 
 
		cake: {
		symbol: 'CAKE',
		address: {
		3797: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
		64668: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},
 
		wbnb: {
		symbol: 'wBNB',
		address: {
		3797: '0xCb3e9919C56efF1004E54175a01e39163a352129',
		64668: '0xCb3e9919C56efF1004E54175a01e39163a352129',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},
		
		ausdt: {
		symbol: 'AUSDT',
		address: {
		3797: '0x7a0b5E86d2C1e873E005780419eeE16ba741808a',
		64668: '',
		},
		decimals: 18,
		projectLink: 'https://www.paxos.com/busd/',
		},
		
		

		busd: {
		symbol: 'AUSDT',
		address: {
		3797: '0x7a0b5E86d2C1e873E005780419eeE16ba741808a',
		64668: '',
		},
		decimals: 18,
		projectLink: 'https://www.paxos.com/busd/',
		},

		syrup: {
		symbol: 'SYRUP',
		address: {
		3797: '0x57f43b94218e6be791c9a3e5d52a1a56bad1277c',
		64668: '0x57f43b94218e6be791c9a3e5d52a1a56bad1277c',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
		},

		bondly: {
		symbol: 'BONDLY',
		address: {
		3797: '0x96058f8C3e16576D9BD68766f3836d9A33158f89',
		64668: '',
		},
		decimals: 18,
		projectLink: 'https://www.bondly.finance/',
		},

		safemoon: {
		symbol: 'SAFEMOON',
		address: {
		3797: '0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3',
		64668: '',
		},
		decimals: 9,
		projectLink: 'https://safemoon.net/',
		},
  
}


export default tokens

import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  
    /* {
    sousId: 0,
    stakingToken: tokens.test,
    earningToken: tokens.test,
    contractAddress: {
      64668: '0xa7b37d6abe333e85b49dec00029517746bbb8fff',
      3797: '0xa7b37d6abe333e85b49dec00029517746bbb8fff',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '35',
    sortOrder: 1,
    isFinished: false,
  },    
  // 0x5Fc67A95649488Ad54488B2650dad5D9922F6188 alvusdtlp alv lp pool contcat smartchif factory 
  // 0xF3eF47559cb8081D5b6Db677E74898Fb2F3B5c77 ausdt walv  pool contcat smartchif factory 
  */
 
 
  
]

export default pools

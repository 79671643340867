import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252,replace 0,1,2) should always be at the top of the file.
   * AUSDT  variable is busd(BUSD) and WALV variable is wbnb (BNB,WBNB)
   */
  {
    pid: 0,
    lpSymbol: 'TEST',
    lpAddresses: {
      64668: '0x58F4C612D23A60b0E20C256a021C26694F0E0359',
      3797: '0x58F4C612D23A60b0E20C256a021C26694F0E0359',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
 
  {
    pid: 1,
    lpSymbol: 'TEST-WALV LP',
    lpAddresses: {
      64668: '',
      3797: '0x760a0ef3ca7184da17ff1f43cc0dd3a7de625532',
    },
    token: tokens.test,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 2,
    lpSymbol: 'AUSDT-WALV LP',
    lpAddresses: {
      64668: '',
      3797: '0xee8fb5bbd126bdb6ce92257e83a768d17ee90d5f',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 3,
    lpSymbol: 'ANA-WALV LP',
    lpAddresses: {
      64668: '',
      3797: '0x52be5e9bc12f41bb09eda3dcfb4b87b5f723a739',
    },
    token: tokens.ana,
    quoteToken: tokens.wbnb,
  },
    
    
  	  

]

export default farms
